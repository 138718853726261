:root {
  --bg-color: #ebebeb;
  --color-dark: #101820;
  --bg-color-1: #f1f4fa;
  --color-primary: #fab502;
}

body {
  background-color: var(--bg-color);
  margin: 0;
  font-family: "Inter", sans-serif, "Poppins", "Roboto";
  color: var(--color-dark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  background-color: var(--bg-color-1);
}

.admin_container {
  max-width: 1920px;
  margin: auto;
}

.btn-primary-dark {
  background-color: var(--color-dark);
  color: #fff;
}

.btn-primary-dark:hover {
  background-color: #0b1016;
  color: #fff;
}

.btn-primary-yellow {
  background-color: var(--color-primary);
  color: #101820;
}
.btn-primary-yellow:hover {
  background-color: transparent;
  border: 1px solid var(--color-primary);
  color: #101820;
}

.bg-dark {
  background: var(--color-dark) !important;
}
.btn-outline-primary-yellow {
  --bs-btn-color: var(--color-primary);
  --bs-btn-border-color: var(--color-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--color-primary);
  --bs-btn-hover-border-color: var(--color-primary);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--color-primary);
  --bs-btn-active-border-color: var(--color-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--color-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--color-primary);
  --bs-gradient: none;
}

.btn-outline-primary-dark {
  --bs-btn-color: var(--color-dark);
  --bs-btn-border-color: var(--color-dark);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--color-dark);
  --bs-btn-hover-border-color: var(--color-dark);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--color-dark);
  --bs-btn-active-border-color: var(--color-dark);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--color-dark);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--color-dark);
  --bs-gradient: none;
}

.bg-color-1 {
  background-color: var(--bg-color-1);
}
.ps-sidebar-container {
  background-color: #fff !important;
  /* color:#fff */
}
.crumbs {
  font-size: 12px;
  color: var(--color-dark);
  opacity: 0.8;
}

.img_btn,
.img_btn:hover {
  border: 1px dashed #3333337d;
}
.hide_input {
  display: none !important;
}

.profile_pic {
  box-shadow: 0 0 3px #00000063;
  border: 2px solid #fff;
}

.tab_ {
  cursor: pointer;
  font-weight: 500;
  opacity: 0.5;
  border-bottom: 2px solid transparent;
}

.tab_.active {
  border-color: var(--color-primary);
  opacity: 1;
}

._lble {
  font-size: 11px;
  font-weight: 500;
}
._lble_ {
  margin-top: 15px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: #10182090;
}
._lbl {
  text-transform: capitalize;
  font-size: 13px;
}

.lnt {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.search_panel {
  height: 0;
}
.search_panel>div {
  visibility: hidden;
  text-indent: -9999999;
  opacity: 0;
  transition: opacity .8s ease-in-out, height .5s ease-in;
}
.search_panel.collapsed {
  height: auto;
 transition: opacity .8s ease-in-out,
 height .5s ease-in;

}
.search_panel.collapsed>div {
  display: block;
  opacity: 1;
  visibility: visible;
  text-indent: 0;
}

.list-group-item.active {
  z-index: 2;
  color: var(--color-primary);
  background-color: var(--color-dark);
  border-color: var(--color-dark);
}